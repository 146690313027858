<template>
  <div>
    <v-container>
      <v-card v-if="tutor" class="mb-5">
        <v-card-title class="pl-6">
          <div class="display-1">
            {{tutor.name}}
<!--             <v-chip class="ma-2" color="orange" text-color="white" label>
              <v-avatar left>
                <v-icon>mdi-alert</v-icon>
              </v-avatar>{{$t("pending-long")}}
            </v-chip> -->
          </div>
        </v-card-title>

        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{tutor.mobile}}</v-list-item-title>
              <v-list-item-subtitle>Mobile</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{tutor.email}}</v-list-item-title>
              <v-list-item-subtitle>Personnel</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-bank</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>+++{{tutor.banking_communication}}+++</v-list-item-title>
              <v-list-item-subtitle>Communication structurée</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card :loading="loading" v-if="loading">...</v-card>
      <v-card :loading="loading" v-if="inscriptions" class="mb-5">
        <v-card-title>{{$t("students")}}</v-card-title>
        <v-data-table must-sort :headers="headers" :items="inscriptions" :options.sync="options" hide-default-footer :loading="loading" class="elevation-1">
         <template slot="item.classe" slot-scope="item">{{item.item.classe}} - {{item.item.day}}</template>

        <template slot="item.actions" slot-scope="item">
          <v-icon small class="mr-2" @click="editItem(item.item)">mdi-pencil</v-icon>
          <v-icon v-if="item.item.status == 'pending'" small @click="deleteItem(item.item)">mdi-delete</v-icon>
        </template>

        <template slot="item.status" slot-scope="item">
          <v-chip :color="getStatusColor(item.item.status)">
            <span class="white--text">{{$t(item.item.status)}}</span>
          </v-chip>
        </template>
        </v-data-table>
      </v-card>

      <v-card :loading="loading" v-if="paiements">
        <v-card-title>Paiements</v-card-title>
        <v-card-text> 
          <div>Montant total : {{getMontantTotal()}} €</div>
          <div>Montant payé : {{getMontantPaye()}} €</div>
          <div>Montant restant : {{getMontantRestant()}} €</div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-title>Historique des paiements</v-card-title>
        <v-data-table must-sort :headers="headersPaiement" :items="paiements" :options.sync="optionsPaiement" hide-default-footer :loading="loading" class="elevation-1">
          <template slot="item.value_date" slot-scope="item">
           {{formatedDate(item.item.value_date)}}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { APIServices } from "@/api/api";
export default {
  data() {
    return {
      inscriptions: null,
      tutor: null,
      paiements : null,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["lastname"],
        sortDesc: [false]
      },
      optionsPaiement: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["value_date"],
        sortDesc: [false]
      }
    };
  },
  created() {
    this.loading = true;
    this.loadTutor();
    this.loadInscriptions();
    this.loadPaiements();
  },
  computed: {
    headers() {
      return [
        { text: this.$t("lastname"), value: "last_name", sortable: false },
        { text: this.$t("firstname"), value: "first_name", sortable: false },
        { text: this.$t("classe"), value: "classe", sortable: false },
        { text: this.$t("status"), value: "status", sortable: false },
        
      ];
    },
    headersPaiement() {
      return [
        { text: "Date du paiement", value: "value_date", sortable: false },
        { text: "Montant", value: "amount", sortable: false },
        
      ];
    }
  },
  methods: {
    formatedDate(date) {
      return this.$moment(date).format("Do MMMM YYYY");
    },
    getMontantTotal() {
      return this.inscriptions.length * 220;
    },
    getMontantPaye() {
      var montantPaye = 0;

      this.paiements.forEach(element => {
        montantPaye += element.amount;
      });
      return montantPaye;
    },
    getMontantRestant() {
      return this.getMontantTotal() - this.getMontantPaye();
    },
    getStatusColor(status) {
      if (status === "pending") {
        return "orange";
      }
      if (status === "pre") {
        return "purple";
      }
      if (status === "default") {
        return "red";
      }
      if (status === "in_order") {
        return "green";
      }
      if (status === "complete") {
        return "teal";
      }
      if (status === "terminated") {
        return "grey";
      }
    },
    getAge(ddn) {
      return this.$moment(ddn, "YYYY-MM-DD").fromNow(true);
    },
    loadTutor() {
      this.loading = true;
      APIServices.get("/tutors/" + this.$route.params.tutor_id)
        .then(response => {
          this.tutor = response.data;
          console.log(this.tutor);
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },
    loadPaiements() {
      this.loading = true;
      APIServices.get("/tutors/" + this.$route.params.tutor_id + "/contributions")
        .then(response => {
          this.paiements = response.data.data;
          console.log(this.paiements);
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },
    loadInscriptions() {
      this.loading = true;
      APIServices.get("/inscriptions?tutor_id=" + this.$route.params.tutor_id)
        .then(response => {
          this.inscriptions = response.data.data;
          console.log(this.inscriptions);
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
